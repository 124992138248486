import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import LocalizedLink from '../LocalizedLink';
import './CookieBanner.scss';

const CookieBanner = () => {
  const { t } = useTranslation();
  const key = 'codutech-cookie-agreement';
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(undefined);

  const onAccept = () => {
    setHasAcceptedCookies(true);
    localStorage.setItem(key, 'agreed');
  };

  useEffect(() => {
    if (localStorage) {
      // Safari with privacy options will have localStorage
      // but won't let us write to it.
      setHasAcceptedCookies(localStorage.getItem(key));
    }

    return () => {};
  }, [hasAcceptedCookies]);

  return (
    <>
      {!hasAcceptedCookies && (
        <section className="cookie-banner">
          <div className="cookie-banner-container">
            <span>
              {t('cookieBanner.text')}{' '}
              <LocalizedLink to="/cookies" onClick={onAccept}>
                {t('cookieBanner.learnMore')}
              </LocalizedLink>
            </span>
            <button className="btn btn-xs btn-primary ml-3" onClick={onAccept} type="button">
              {t('cookieBanner.gotIt')}
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default CookieBanner;
