module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-react-helmet-canonical-urls-i18n/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://codutech.com","locales":[{"default":true,"locale":"en","culture":"en_US","label":"English","routes":{"/":"/","/blog":"/blog/","/contact":"/contact/","/privacy":"/privacy/","/terms":"/terms/","/cookies":"/cookies/","/case-studies":"/case-studies/","/case-studies/schedulino":"/case-studies/schedulino/","/case-studies/roomex":"/case-studies/roomex/","/services":"/services/","/services/#web-development":"/services/#web-development","/services/#aws-serverless-development":"/services/#aws-serverless-development","/services/#saas-application-development":"/services/#saas-application-development","/404":"/404/","/404.html":"/404.html"}},{"locale":"cs","label":"Čeština","culture":"cs_CZ","routes":{"/":"/","/blog":"/blog/","/contact":"/kontakt/","/privacy":"/ochrana-soukromi/","/terms":"/podminky-pouzivani/","/cookies":"/cookies/","/case-studies":"/case-studies/","/case-studies/schedulino":"/case-studies/schedulino/","/case-studies/roomex":"/case-studies/roomex/","/services":"/sluzby/","/services/#web-development":"/sluzby/#web-development","/services/#aws-serverless-development":"/sluzby/#aws-serverless-development","/services/#saas-application-development":"/sluzby/#saas-application-development","/404":"/404/","/404.html":"/404.html"}},{"locale":"sk","label":"Slovenčina","culture":"sk_SK","routes":{"/":"/","/blog":"/blog/","/contact":"/kontakt/","/privacy":"/ochrana-sukromia/","/terms":"/podmienky-pouzivania/","/cookies":"/cookies/","/case-studies":"/case-studies/","/case-studies/schedulino":"/case-studies/schedulino/","/case-studies/roomex":"/case-studies/roomex/","/services":"/sluzby/","/services/#web-development":"/sluzby/#web-development","/services/#aws-serverless-development":"/sluzby/#aws-serverless-development","/services/#saas-application-development":"/sluzby/#saas-application-development","/404":"/404/","/404.html":"/404.html"}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PB47SR5","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://aea92d745abe4aa0b3491e96f57d755d@o568832.ingest.sentry.io/5714175","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Codutech","short_name":"Codutech","start_url":"/","background_color":"#ffffff","theme_color":"#28A3C9","display":"minimal-ui","icon":"src/assets/images/codutech_icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"168cdda37944cc8f67aa6222cd8b6683"},
    }]
