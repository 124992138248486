import React, { useEffect } from 'react';

import CookieBanner from '../components/CookieBanner/CookieBanner';
import ErrorBoundary from '../components/ErrorBoundary';
import Footer from '../components/Footer';
import Header from '../components/Header';
// eslint-disable-next-line import/no-named-as-default-member
import withI18next from '../i18n/withI18next';

import '../styles/main.scss';

const Layout = ({ children }) => {
  let AOS;

  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    // eslint-disable-next-line global-require
    AOS = require('aos');

    AOS.init({
      duration: 650,
      once: true,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  return (
    <ErrorBoundary>
      <Header />
      <main className="overflow-hidden">{children}</main>
      <Footer />
      <CookieBanner />
    </ErrorBoundary>
  );
};

export default withI18next()(Layout);
