const getSchedulinoLink = (locale) => {
  let domainPostfix;

  switch (locale) {
    case 'en':
      domainPostfix = 'com';
      break;
    case 'cs':
      domainPostfix = 'cz';
      break;
    default:
      domainPostfix = 'sk';
  }

  return `https://schedulino.${domainPostfix}/`;
};

module.exports = getSchedulinoLink;
