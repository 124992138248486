/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from 'gatsby';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import { languages } from '../i18n';

function SEO({ description, lang, meta, title, isBlogPost }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author {
              name
              summary
            }
            siteUrl
            social {
              twitter
            }
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const currentLanguage = languages.find((l) => l.locale === lang);
  const languagesWithoutCurrent = languages.filter((l) => l.locale !== lang);
  const imageUrl = `${site.siteMetadata.siteUrl}/static/twitter_bg.png?v1.1`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.social ? site.siteMetadata.social.twitter : '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: imageUrl,
        },
        {
          property: 'og:image:alt',
          content: title,
        },
      ]
        .concat([
          /* Open Graph */
          {
            property: 'og:title',
            content: title,
          },
          {
            property: 'og:site_name',
            content: site.siteMetadata.title,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: 'og:type',
            content: isBlogPost ? 'article' : 'website',
          },
          {
            property: 'og:image',
            content: imageUrl,
          },
          {
            property: 'og:locale',
            content: currentLanguage && currentLanguage.culture,
          },
        ])
        .concat(
          languagesWithoutCurrent.map((l) => ({
            property: 'og:locale:alternate',
            content: l.culture,
          })),
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
    isBlogPost: false
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
    isBlogPost: PropTypes.bool
};

export default SEO;
