/* eslint-disable import/no-commonjs */
// eslint-disable-next-line import/no-extraneous-dependencies
const React = require('react');
// eslint-disable-next-line import/no-extraneous-dependencies
const { Helmet } = require('react-helmet');

const isExcluded = (excludes, element) => {
  if (!Array.isArray(excludes)) return false;

  const elementFormatted = element.replace(/\/+$/, '');

  return excludes.some((exclude) => {
    if (exclude instanceof RegExp) return elementFormatted.match(exclude);

    return exclude.includes(elementFormatted);
  });
};

const getPathName = (noTrailingSlash, pathName) => {
  let pathname = pathName || '/';

  if (noTrailingSlash && pathname.endsWith('/')) {
    pathname = pathname.substring(0, pathname.length - 1);
  }

  return pathname;
};

// cloned and edit code from https://github.com/NickCis/gatsby-plugin-react-helmet-canonical-urls
module.exports = ({ element, props }, pluginOptions) => {
  if (
    pluginOptions &&
    pluginOptions.siteUrl &&
    !isExcluded(pluginOptions.exclude, props.location.pathname)
  ) {
    const pathname = getPathName(pluginOptions.noTrailingSlash, props.location.pathname);

    const myUrl = `${pluginOptions.siteUrl}${pathname.endsWith('/') ? pathname : `${pathname}/`}${
      props.location.search
    }${props.location.hash}`;

    let alternateMetas = [];

    if (pluginOptions.locales && props.pageContext) {
      const defaultLocale = pluginOptions.locales.find((l) => l.default);

      alternateMetas = pluginOptions.locales
        .filter((l) => l.locale !== props.pageContext.locale)
        .map((l) => {
          const isDefaultLocale = l.locale === defaultLocale.locale;
          const locale = isDefaultLocale ? '' : `/${l.locale}`;
          const route =
              // eslint-disable-next-line no-nested-ternary
            isDefaultLocale && props.pageContext.originalPath === '/'
              ? '/'
              : pathname === '/'
              ? `${locale}/`
              : `${locale}${l.routes[getPathName(true, props.pageContext.originalPath) || '/']}`;

            // eslint-disable-next-line no-console
          console.log(
            'pathname',
            pathname,
            'route',
            route,
            'originalPath',
            getPathName(true, props.pageContext.originalPath),
          );

          return {
            rel: 'alternate',
            href: `${pluginOptions.siteUrl}${getPathName(pluginOptions.noTrailingSlash, route)}${
              props.location.search
            }${props.location.hash}`,
            hreflang: l.locale,
          };
        });
      alternateMetas.push({
        rel: 'alternate',
        href: myUrl,
        hreflang: 'x-default',
      });
    }

    return (
      <>
        <Helmet
          link={[
            {
              rel: 'canonical',
              href: myUrl,
            },
          ].concat(alternateMetas)}
        />
        {element}
      </>
    );
  }

  return element;
};
