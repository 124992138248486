import { faAngleDown, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Dropdown, Nav, NavItem } from 'react-bootstrap';

import LocaleContext from '../context/localeContext';
import { getLocalizedPath, languages } from '../i18n';

const LanguageSwitcher = () => {
  const { locale, originalPath } = useContext(LocaleContext);

  const currentLanguage =
    languages.find((lang) => lang.locale === locale) || languages.find((lang) => lang.default);
  const languagesOptions = languages.filter((lang) => lang.locale !== currentLanguage.locale);

  return (
    <Dropdown as={NavItem}>
      <Dropdown.Toggle as={Nav.Link}>
        <FontAwesomeIcon icon={faGlobe} fixedWidth width="14" />
        &nbsp;{currentLanguage.label}&nbsp;
        <FontAwesomeIcon icon={faAngleDown} fixedWidth width="14" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {languagesOptions.map((lang) => (
          <Dropdown.Item href={getLocalizedPath(originalPath, lang.locale)} key={lang.locale}>
            {lang.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSwitcher;
