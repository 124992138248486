import React, { useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import logo from '../assets/svg/logos/codutech.svg';

import LanguageSwitcher from './LanguageSwitcher';
import LocalizedLink from './LocalizedLink';

const Header = () => {
  const { t } = useTranslation();
  const [navExpanded, setNavExpanded] = useState(false);

  const closeNav = () => {
    setNavExpanded(false);
  };

  return (
    <header>
      <Navbar onToggle={setNavExpanded} expanded={navExpanded} expand="lg">
        <Container>
          <LocalizedLink to="/" className="navbar-brand" onClick={closeNav}>
            <img className="max-w-23rem max-w-md-27rem mx-auto" alt="Codutech logo" src={logo} />
          </LocalizedLink>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Item>
                <LocalizedLink to="/services" className="nav-link" onClick={closeNav}>
                  {t('navigation.services')}
                </LocalizedLink>
              </Nav.Item>
              <Nav.Item>
                <LocalizedLink to="/case-studies" className="nav-link" onClick={closeNav}>
                  {t('navigation.caseStudies')}
                </LocalizedLink>
              </Nav.Item>
              {/*<Nav.Item>*/}
              {/*  <LocalizedLink to="/blog" className="nav-link" onClick={closeNav}>*/}
              {/*    {t('navigation.blog')}*/}
              {/*  </LocalizedLink>*/}
              {/*</Nav.Item>*/}
              <LanguageSwitcher />
              <div className="d-flex align-items-center">
                <LocalizedLink
                  to="/contact"
                  className="btn btn-sm btn-primary transition-3d-hover ml-md-2"
                >
                  {t('navigation.cta')}
                </LocalizedLink>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
