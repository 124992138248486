// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import error404 from '../assets/svg/illustrations/error-404.svg';
import errorNumber500 from '../assets/svg/illustrations/error-number-500.svg';

import LocalizedLink from './LocalizedLink';
import SEO from './Seo';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  render() {
    const { error } = this.state;
    const { children, i18n, t } = this.props;

    if (error) {
      return (
        <>
          <SEO title={t('meta.500.title')} lang={i18n.language} />
          <div className="d-lg-flex" style={{ backgroundImage: `url(${error404})` }}>
            <div className="container d-lg-flex align-items-lg-center min-vh-lg-100 space-bottom-4 space-top-3">
              <div className="w-lg-60 w-xl-50">
                <div className="mb-4">
                  <img className="max-w-23rem mb-3" src={errorNumber500} alt="SVG Illustration" />
                  <p className="lead">Oops! {t('error500.title')}</p>
                </div>

                <LocalizedLink
                  to="/"
                  className="btn btn-wide btn-primary transition-3d-hover text-capitalize"
                >
                  {t('notFoundPage.subtitle')}
                </LocalizedLink>
              </div>
            </div>
          </div>
        </>
      );
    }

    // when there's not an error, render children untouched
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withTranslation()(ErrorBoundary);
