module.exports = [
  {
    default: true,
    locale: 'en',
    culture: 'en_US',
    label: 'English',
    routes: {
      '/': '/',
      '/blog': '/blog/',
      '/contact': '/contact/',
      '/privacy': '/privacy/',
      '/terms': '/terms/',
      '/cookies': '/cookies/',
      '/case-studies': '/case-studies/',
      '/case-studies/schedulino': '/case-studies/schedulino/',
      '/case-studies/roomex': '/case-studies/roomex/',
      '/services': '/services/',
      '/services/#web-development': '/services/#web-development',
      '/services/#aws-serverless-development': '/services/#aws-serverless-development',
      '/services/#saas-application-development': '/services/#saas-application-development',
      '/404': '/404/',
      '/404.html': '/404.html',
    },
  },
  {
    locale: 'cs',
    label: 'Čeština',
    culture: 'cs_CZ',
    routes: {
      '/': '/',
      '/blog': '/blog/',
      '/contact': '/kontakt/',
      '/privacy': '/ochrana-soukromi/',
      '/terms': '/podminky-pouzivani/',
      '/cookies': '/cookies/',
      '/case-studies': '/case-studies/',
      '/case-studies/schedulino': '/case-studies/schedulino/',
      '/case-studies/roomex': '/case-studies/roomex/',
      '/services': '/sluzby/',
      '/services/#web-development': '/sluzby/#web-development',
      '/services/#aws-serverless-development': '/sluzby/#aws-serverless-development',
      '/services/#saas-application-development': '/sluzby/#saas-application-development',
      '/404': '/404/',
      '/404.html': '/404.html',
    },
  },
  {
    locale: 'sk',
    label: 'Slovenčina',
    culture: 'sk_SK',
    routes: {
      '/': '/',
      '/blog': '/blog/',
      '/contact': '/kontakt/',
      '/privacy': '/ochrana-sukromia/',
      '/terms': '/podmienky-pouzivania/',
      '/cookies': '/cookies/',
      '/case-studies': '/case-studies/',
      '/case-studies/schedulino': '/case-studies/schedulino/',
      '/case-studies/roomex': '/case-studies/roomex/',
      '/services': '/sluzby/',
      '/services/#web-development': '/sluzby/#web-development',
      '/services/#aws-serverless-development': '/sluzby/#aws-serverless-development',
      '/services/#saas-application-development': '/sluzby/#saas-application-development',
      '/404': '/404/',
      '/404.html': '/404.html',
    },
  },
];
