import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

import logo from '../assets/svg/logos/codutech2.svg';

import LocalizedLink from './LocalizedLink';

const Footer = () => {
  const { t } = useTranslation();
  const copyrightDate = new Date().getFullYear();

  return (
    <footer className="bg-dark">
      <div className="container">
        <div className="space-top-2 space-bottom-1">
          <div className="row justify-content-lg-between space-bottom-1 space-bottom-lg-2">
            <div className="col-md-4 mb-4 mb-lg-0">
              <div className="mb-4">
                <LocalizedLink to="/">
                  <img src={logo} alt="Codutech logo" className="max-w-23rem max-w-md-27rem" />
                </LocalizedLink>
              </div>
              <p className="w-md-80 text-white opacity-sm font-size-1">
                {t('footer.productDescription')}
              </p>

              <ul className="list-inline mb-0">
                <li className="list-inline-item pr-1">
                  <a
                    className="btn btn-xs btn-icon btn-soft-light"
                    href="https://twitter.com/martinmicunda"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Twitter"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
                <li className="list-inline-item pr-1">
                  <a
                    className="btn btn-xs btn-icon btn-soft-light"
                    href="https://www.linkedin.com/in/martinmicunda/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
                <li className="list-inline-item pr-1">
                  <a
                    className="btn btn-xs btn-icon btn-soft-light"
                    href="https://github.com/martinmicunda"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Github"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-2 mb-4 mb-lg-0">
              <h6 className="text-white">{t('navigation.services')}</h6>
              <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                <li className="nav-item">
                  <LocalizedLink to="/services/#web-development" className="nav-link">
                    {t('navigation.webDevelopment')}
                  </LocalizedLink>
                </li>
                <li className="nav-item">
                  <LocalizedLink to="/services/#aws-serverless-development" className="nav-link">
                    AWS / Serverless
                  </LocalizedLink>
                </li>
                <li className="nav-item">
                  <LocalizedLink to="/services/#saas-application-development" className="nav-link">
                    {t('navigation.saaSDevelopment')}
                  </LocalizedLink>
                </li>
                {/* <li className="nav-item"> */}
                {/* <LocalizedLink to="/services#mobile-development"
                className="nav-link">{t('navigation.mobileDevelopment')}</LocalizedLink> */}
                {/* </li> */}
              </ul>
            </div>
            <div className="col-6 col-md-3 pl-lg-6 mb-4 mb-lg-0">
              <h6 className="text-white">{t('footer.company')}</h6>
              <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                <li className="nav-item">
                  <LocalizedLink to="/case-studies" className="nav-link">
                    {t('navigation.caseStudies')}
                  </LocalizedLink>
                </li>
                {/*<li className="nav-item">*/}
                {/*  <LocalizedLink to="/blog" className="nav-link">*/}
                {/*    {t('navigation.blog')}*/}
                {/*  </LocalizedLink>*/}
                {/*</li>*/}
                <li className="nav-item">
                  <LocalizedLink to="/privacy" className="nav-link">
                    {t('navigation.privacy')}
                  </LocalizedLink>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-3">
              <h6 className="text-white">{t('footer.contact')}</h6>
              <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                <li className="nav-item">
                  <a className="nav-link" href="mailto:hello@codutech.com">
                    <span className="media align-items-center">
                      <FontAwesomeIcon icon={faEnvelope} fixedWidth className="mr-2" />
                      <span className="media-body">hello@codutech.com</span>
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <button className="btn btn-link nav-link" type="button">
                    <span className="media align-items-center">
                      <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth className="mr-2" />
                      <span className="media-body">29 Holles Place, Dublin 2, Ireland</span>
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <hr className="opacity-xs my-0" />

          <div className="row text-center space-top-1">
            <p className="w-100 text-white opacity-sm small mb-0">
              &copy; {copyrightDate} Codutech. {t('footer.allRightReserved')}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
