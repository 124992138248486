import { Link } from 'gatsby';
import React, { useContext } from 'react';

import LocaleContext from '../context/localeContext';
import { getLocalizedPath } from '../i18n';

// Use the globally available context to choose the right path
const LocalizedLink = ({ to, ...props }) => {
  const { locale } = useContext(LocaleContext);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link {...props} to={getLocalizedPath(to, locale)} activeClassName="active" />;
};

export default LocalizedLink;
