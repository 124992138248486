import i18n from 'i18next';

const fn = () => {
  i18n.init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: { useSuspense: false },
  });

  return i18n;
};

export default fn;
